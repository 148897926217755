import { FC, useState, useEffect } from "react";
import { Etichette } from "../StayCard";
import { HandlerTipologiaProps } from "components/SectionHeroArchivePage/SectionHeroArchivePage";

interface ProdottiModel {
    nome: string;
    tariffa_adulto: string;
    tariffa_bambino: string;
}

export interface StayCardTypologiesProps {
    info?: any;
    item?: any;
    etichette_stati?: Etichette[] | null;
    _setTipologia: ({ tipologia_id, evento_id }: any) => void;
}


const StayCardTypologies: FC<StayCardTypologiesProps> = ({ info, item, etichette_stati, _setTipologia }) => {

    const [tmp, setServices] = useState<ProdottiModel[]>([]);
    useEffect(() => {
        getProdottiService();
    }, [item])

    const getProdottiService = () => {
        fetch(`https://booking.jooba.it/getProdottiService?evento_id=${item.evento_id}&tipo_id=${item.tipo_id}`, {
            method: "GET",
        })
            .then((response) => response.json())
            .then(async (response) => {
                setServices(response)
            })
            .catch((error) => console.log(error));
    };
    if (etichette_stati) {



        const tariffaAdulti = tmp && tmp.length > 0 && tmp[0].tariffa_adulto ? tmp[0].tariffa_adulto : "0";


        let stato_id = 0;
        if (item?.stato == 'APERTO') stato_id = 5;
        else if (item?.stato == "ATTESA") stato_id = 6;
        else if (item?.stato == "RICHIESTA") stato_id = 7;
        else if (item?.stato == "CHIUSO") stato_id = 8;
        let etichetta = etichette_stati?.filter((et: any) => et.stato_id === stato_id && et.tipologia_id == item.tipo_id);


        let bambini = 0;
        let bambiniBaby = 0;
        let animali = true;

        if (info) {
            bambini = info.bambini;
            bambiniBaby = info.bambiniBaby;
            animali = info.animali;
        }

        if (item?.bambini_ammessi == 0 && (bambini + bambiniBaby) > 0) return null;

        if (item?.animali_ammessi == 0 && animali) return null;


        if (item?.stato === 'APERTO') {
            return (<>
                <button onClick={() =>

                    _setTipologia({ tipologia_id: item.id, evento_id: item.evento_id })
                } className="w-full mt-2 w-full text-green-800 bg-green-100 nc-Badge px-2.5 py-2 rounded-full font-medium transition-colors hover:bg-green-700 hover:text-white duration-300 text-sm">
                    <i className="la la-check-circle mr-4 la-lg"></i>
                    {`${item.etichetta !== "" ? item.etichetta : item.tipo}${etichetta !== null ? (etichetta.length > 0 ? ((etichetta[0].etichetta_stato != null && etichetta[0].etichetta_stato !== '') ? `: ${etichetta[0].etichetta_stato}` : '') : '') : ''}`}
                </button>
                <div className="p-2" style={{ textAlign: 'left' }}>
                    <div className="flex justify-between text-neutral-6000 dark:text-neutral-300">
                        <span className="font-bold">Tariffa adulti</span>{" "}
                        <span>
                            €{(parseFloat(item.tariffa_struttura || 0)).toFixed(2)}
                        </span>
                    </div>
                    <div className="flex justify-between text-neutral-6000 dark:text-neutral-300 text-sm">
                        <span style={{ color: "green" }}>{tmp[0]?.nome}</span>{" "}
                        <span style={{ color: "green" }}>
                            €{(parseFloat(tariffaAdulti)).toFixed(2)}
                        </span>
                    </div>
                    <div className="flex justify-between text-neutral-6000 dark:text-neutral-300 text-sm">
                        <span style={{ color: "green" }}>{`Spesa minima Food & Beverage`}</span>{" "}
                        <span style={{ color: "green" }}>
                            €{(parseFloat(item?.tariffa_struttura || 0) - parseFloat(tariffaAdulti)).toFixed(2)}
                        </span>
                    </div>
                </div>
            </>

            );
        }
        if (item?.stato === 'RICHIESTA') {
            return (
                <button onClick={() =>

                    _setTipologia({ tipologia_id: item.id, evento_id: item.evento_id })
                } className="w-full mt-2 w-full text-yellow-800 bg-yellow-100 nc-Badge px-2.5 py-2 rounded-full font-medium transition-colors hover:bg-yellow-700 hover:text-white duration-300 text-sm">
                    <i className="la la-check-circle mr-4 la-lg"></i>
                    {`${item.etichetta !== "" ? item.etichetta : item.tipo}${etichetta !== null ? (etichetta.length > 0 ? ((etichetta[0].etichetta_stato != null && etichetta[0].etichetta_stato !== '') ? `: ${etichetta[0].etichetta_stato}` : '') : '') : ''}`}
                </button>
            );
        }
        if (item?.stato === 'ATTESA') {
            return (
                <button onClick={() =>

                    _setTipologia({ tipologia_id: item.id, evento_id: item.evento_id })
                } className="w-full mt-2 w-full text-blue-800 bg-blue-100 nc-Badge px-2.5 py-2 rounded-full font-medium transition-colors hover:bg-blue-700 hover:text-white duration-300 text-sm">
                    <i className="la la-check-circle mr-4 la-lg"></i>
                    {`${item.etichetta !== "" ? item.etichetta : item.tipo}${etichetta !== null ? (etichetta.length > 0 ? ((etichetta[0].etichetta_stato != null && etichetta[0].etichetta_stato !== '') ? `: ${etichetta[0].etichetta_stato}` : '') : '') : ''}`}
                </button>
            );
        }
        if (item?.stato === 'CHIUSO') {
            return (
                <button onClick={() =>

                    _setTipologia({})
                } className="w-full mt-2 w-full text-red-800 bg-red-100 nc-Badge py-2 rounded-full font-medium text-sm">
                    {`${item.etichetta !== "" ? item.etichetta : item.tipo}${etichetta !== null ? (etichetta.length > 0 ? ((etichetta[0].etichetta_stato != null && etichetta[0].etichetta_stato !== '') ? `: ${etichetta[0].etichetta_stato}` : '') : '') : ''}`}
                </button>
            );
        }

        return <></>;
    } else return <></>;

};

export default StayCardTypologies;
