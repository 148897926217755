import React, { FC, useState, useEffect, ChangeEvent, Fragment } from "react";
import Input from "shared/Input/Input";
import Label from "components/Label/Label";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import { useLocation, useHistory } from "react-router-dom";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import Checkbox from "shared/Checkbox/Checkbox";
import { Link } from "react-router-dom";
import Select from "shared/Select/Select";
import FlightCardNote from "components/FlightCard/FlightCardNote";
import SaleOffBadge from "components/SaleOffBadge/SaleOffBadge";
import SaleOffBadgeGrey from "components/SaleOffBadge/SaleOffBadgeGrey";
import ButtonSecondary from "shared/Button/ButtonSecondary";
import PhoneInput from "shared/Input/PhoneInput";
import Button from "shared/Button/Button";
import { Dialog, Transition } from "@headlessui/react";
import ButtonClose from "shared/ButtonClose/ButtonClose";

export interface CheckOutPageProps {
  className?: string;
  metodo?: string;
}

export interface ServizioType {
  nome: string;
  price_adulti: string;
  price_bambino: string;
}
export interface TipologiaServiziType {
  id: string;
  tipologia_id: string;
  nome: string;
  isChecked: boolean;
  prezzo_adulto: string;
  prezzo_bambino: string;
  quantita: number;
}

interface State {
  id: string;
  animali_ammessi: number;
  bambini_ammessi: number;
  actionOpzioniEvento: any;
  adulti: number;
  text_tipologiaSoldOut: string;
  text_utenteAbilitato: string;
  text_statoRichiestaOnline: string;
  text_statoRichiestaStruttura: string;
  text_richiestaCaparra: string;
  text_statoAttesaOnline: string;
  text_statoAttesaStruttura: string;
  text_prenotazioneEsistente: string;
  text_swalCaparra: string;
  bambini: number;
  bambiniBaby: number;
  tipologia: string;
  referente_required?: number;
  val_pren?: string;
  val_pren_inferiore?: string;
  telefono_reg?: string;
  evento_id?: number;
  referente?: number;
  data_finale?: string;
  animali?: boolean;
  quota_una_tantum?: number;
  area?: string;
  area_id?: number;
  ta?: string;
  tb?: string;
  serviziSelected?: [ServizioType];
  totale_bambini_servizi?: number;
  totale_adulti_servizi?: number;
  title_note_token?: string;
  note_token?: string;
  prezzoServizioTipologiaAdulto?: number;
  prezzoServizioTipologiaBambino?: number;
  arrayServiziTemp?: [TipologiaServiziType];
  accordion_bambini?: string;
  accordion_richiestadiprenotazione?: string;
  accordion_listadiattesa?: string;
  testo_accordion_bambini?: string;
  testo_accordion_richiestadiprenotazione?: string;
  testo_accordion_listadiattesa?: string;
  day?: string;
  acconto?: string;
  color_accordion_listadiattesa?: string;
  color_accordion_richiestediprenotazione?: string;
  color_accordion_bambini?: string;
  color_title_note_token?: string;
  color_note_generali?: string;
  color_accordion_acconto?: string;
  testo_accordion_acconto?: string;
  accordion_acconto?: string;
  open_accordion_note_token?: string;
  open_accordion_bambini?: string;
  open_accordion_listadiattesa?: string;
  open_accordion_richiestediprenotazione?: string;
  open_accordion_acconto?: string;
  open_accordion_note_generali?: string;
  evento_token?: number;
  prezzoServizioTipologiaBambinoToken?: number;
  prezzoServizioTipologiaAdultoToken?: number;
  costoProdottoOpzione?: number;
  metodoPagamento?: string;
  arrayScelto?: {
    id: number;
    evento_id: number;
    note_orario: string;
    paga_online: number;
    paga_struttura: number;
    stato: string;
    tariffa_online: string;
    tariffa_online_bambino: string;
    tariffa_struttura: string;
    tariffa_struttura_bambino: string;
    tariffa_walkin: string;
    tariffa_walkin_bambino: string;
    tipo: string;
    etichetta: string;
    tipo_id: number;
    note_generali?: string;
    new_customer?: string;
    stato_tesserato?: string;
    stato_bannato?: string;
    stato_nuovo_cliente?: string;
    stato_anagrafato_positivo?: string;
    stato_anagrafato_intermedio?: string;
    stato_anagrafato_negativo?: string;
    text_new_customer?: string;
    title_note_generali?: string;
    importo_acconto?: number;
    importo_acconto_bambini?: number;
  };
}

const CheckOutPage: FC<CheckOutPageProps> = ({ className = "" }) => {
  const europeanPrefixes = [
    "+30", // Grecia
    "+31", // Paesi Bassi
    "+32", // Belgio
    "+33", // Francia
    "+34", // Spagna
    "+36", // Ungheria
    "+39", // Italia
    "+40", // Romania
    "+41", // Svizzera
    "+43", // Austria
    "+44", // Regno Unito
    "+45", // Danimarca
    "+46", // Svezia
    "+47", // Norvegia
    "+48", // Polonia
    "+49", // Germania
  ];

  const [prenotazione, setPrenotazione] = useState<State>();
  const location = useLocation<State>();

  const [showFieldAnagrafica, setShowFieldAnagrafica] = useState(false);

  const [nuovoCliente, setNuovoCliente] = useState(false);
  const [clienteAnagrafato, setClienteAnagrafato] = useState(false);
  const [clienteTesserato, setClienteTesserato] = useState(false);
  const [clienteAbilitato, setClienteNotAbilitato] = useState(true);
  const [sconto, setSconto] = useState("");
  const [clienteAnagrafatoPositivo, setClienteAnagrafatoPositivo] = useState(false);
  const [clienteAnagrafatoNegativo, setClienteAnagrafatoNegativo] = useState(false);
  const [clienteAnagrafatoIntermedio, setClienteAnagrafatoIntermedio] = useState(false);
  const [scontoCode, setScontoCode] = useState("");
  const [statoFinale, setStatoButtonFinish] = useState("");

  const [id, setId] = useState("");
  const [nome, setNome] = useState("");
  const [cognome, setCognome] = useState("");
  const [trovato, setTrovato] = useState(false);
  const [emailmancante, setEmailMancante] = useState(false);
  const [email, setEmail] = useState("");
  const [telefonoSearch, setSearchTel] = useState("");
  const [cond3, setPrivacy] = useState(false);
  const [cond1, setCond1] = useState(true);
  const [referents, setReferents] = useState([] as any);
  const [agent_id, setAgente] = useState("");
  const [stato, setStato] = useState("");
  const [abilitoCompilazioneNewClient, setAbilitoCompilazioneNewClient] = useState(true);
  const [acconto, setAcconto] = useState(0);
  const [text_acconto, setTextAcconto] = useState("");
  const [importoAcconto, setImportoAcconto] = useState(0);
  const [metodoPagamentoScelto, setMetodoPagamento] = useState("");

  //Props della prenotazione
  const options = prenotazione?.arrayServiziTemp || [];
  const prezzoServizioTipologiaAdulto = prenotazione?.prezzoServizioTipologiaAdulto || 0;
  const prezzoServizioTipologiaBambino = prenotazione?.prezzoServizioTipologiaBambino || 0;
  const referente_required = prenotazione?.referente_required;
  const evento_id = prenotazione?.evento_id;
  const adulti = prenotazione?.adulti || 0;
  const text_tipologiaSoldOut = prenotazione?.text_tipologiaSoldOut || "";
  const animali_ammessi = prenotazione?.animali_ammessi || 0;
  const bambini_ammessi = prenotazione?.bambini_ammessi || 0;
  const actionOpzioniEvento = prenotazione?.actionOpzioniEvento || 0;
  const text_utenteAbilitato = prenotazione?.text_utenteAbilitato || "";
  const text_richiestaCaparra = prenotazione?.text_richiestaCaparra || "";
  const text_statoRichiestaOnline = prenotazione?.text_statoRichiestaOnline || "";
  const text_statoRichiestaStruttura = prenotazione?.text_statoRichiestaStruttura || "";
  const text_prenotazioneEsistente = prenotazione?.text_prenotazioneEsistente || "";
  const text_statoAttesaOnline = prenotazione?.text_statoAttesaOnline || "";
  const text_statoAttesaStruttura = prenotazione?.text_statoAttesaStruttura || "";

  const text_swalCaparra = prenotazione?.text_swalCaparra || "";
  const bambini = prenotazione?.bambini || 0;
  const bambiniBaby = prenotazione?.bambiniBaby || 0;
  const referente = prenotazione?.referente;
  const dataEvento = prenotazione?.data_finale || "";
  const val_pren_inferiore = prenotazione?.val_pren_inferiore || "";
  const val_pren = prenotazione?.val_pren || "";

  const day = prenotazione?.day;
  const animali = prenotazione?.animali;
  const area = prenotazione?.area;
  const area_id = prenotazione?.area_id;
  const ta = prenotazione?.ta || "";
  const tb = prenotazione?.tb || "";
  const quota_una_tantum = prenotazione?.quota_una_tantum || 0;
  const note_token = prenotazione?.note_token || "";
  const title_note_token = prenotazione?.title_note_token || "Note Formule Token";
  // const serviziSelected = prenotazione?.serviziSelected;
  const [firstOpen, setFirstOpen] = useState(1);
  const [totale_adulti_servizi, setTotaleAdultiServizi] = useState(0);
  const [totale_bambini_servizi, setTotaleBambiniServizi] = useState(0);
  const [serviziSelected, setServiziSelected] = useState<ServizioType[]>([]);
  // const totale_adulti_servizi = prenotazione?.totale_adulti_servizi || 0;

  // const totale_bambini_servizi = prenotazione?.totale_bambini_servizi || 0;

  const accordion_bambini = prenotazione?.accordion_bambini;
  const accordion_richiestadiprenotazione = prenotazione?.accordion_richiestadiprenotazione;
  const accordion_listadiattesa = prenotazione?.accordion_listadiattesa;
  const testo_accordion_bambini = prenotazione?.testo_accordion_bambini;
  const testo_accordion_richiestadiprenotazione = prenotazione?.testo_accordion_richiestadiprenotazione;
  const testo_accordion_listadiattesa = prenotazione?.testo_accordion_listadiattesa;
  const arrayScelto = prenotazione?.arrayScelto;
  const acconto_tipologia = prenotazione?.acconto?.split(",");
  const color_accordion_listadiattesa = prenotazione?.color_accordion_listadiattesa;
  const color_accordion_richiestediprenotazione = prenotazione?.color_accordion_richiestediprenotazione;
  const color_accordion_bambini = prenotazione?.color_accordion_bambini;
  const color_title_note_token = prenotazione?.color_title_note_token;
  const color_note_generali = prenotazione?.color_note_generali;
  const accordion_acconto = prenotazione?.accordion_acconto;
  const testo_accordion_acconto = prenotazione?.testo_accordion_acconto;
  const color_accordion_acconto = prenotazione?.color_accordion_acconto;
  const evento_token = prenotazione?.evento_token;
  const prezzoServizioTipologiaBambinoToken = prenotazione?.prezzoServizioTipologiaBambinoToken || 0;
  const prezzoServizioTipologiaAdultoToken = prenotazione?.prezzoServizioTipologiaAdultoToken || 0;
  const costoProdottoOpzione = prenotazione?.costoProdottoOpzione || 0;
  const open_accordion_note_token = prenotazione?.open_accordion_note_token;
  const open_accordion_bambini = prenotazione?.open_accordion_bambini;
  const open_accordion_listadiattesa = prenotazione?.open_accordion_listadiattesa;
  const open_accordion_richiestediprenotazione = prenotazione?.open_accordion_richiestediprenotazione;
  const open_accordion_acconto = prenotazione?.open_accordion_acconto;
  const open_accordion_note_generali = prenotazione?.open_accordion_note_generali;

  //PROPS arrayscelto
  const tipologia_id = arrayScelto?.id;
  const tipologia = arrayScelto?.etichetta != "" ? arrayScelto?.etichetta : arrayScelto?.tipo;
  const note_orario = arrayScelto?.note_orario;

  const [viewCart, setViewCart] = useState(true);
  //  const tariffa_online = arrayScelto?.tariffa_online || "0";
  //  const tariffa_online_bambino = arrayScelto?.tariffa_online_bambino || "0";

  const tipo_id = arrayScelto?.tipo_id;
  const note_generali = arrayScelto?.note_generali || "";
  const stato_prenotazione = arrayScelto?.stato || "";
  const new_customer = arrayScelto?.new_customer;
  const text_new_customer = arrayScelto?.text_new_customer;

  const stato_tesserato = arrayScelto?.stato_tesserato || "";
  const stato_bannato = arrayScelto?.stato_bannato || "";

  const stato_nuovo_cliente = arrayScelto?.stato_nuovo_cliente || "";
  const stato_anagrafato_positivo = arrayScelto?.stato_anagrafato_positivo || "";
  const stato_anagrafato_intermedio = arrayScelto?.stato_anagrafato_intermedio || "";

  const stato_anagrafato_negativo = arrayScelto?.stato_anagrafato_negativo || "";
  const title_note_generali = arrayScelto?.title_note_generali || "Note generali";

  //Constanti di calcolo
  // const tot_adulti_struttura = parseFloat(tariffa_struttura || "0") * (prenotazione?.adulti || 0) + (prezzoServizioTipologiaAdulto || 0) * (prenotazione?.adulti || 0);
  // const tot_bambini_struttura = parseFloat(tariffa_struttura_bambino || "0") * (prenotazione?.bambini || 0) +  (prezzoServizioTipologiaBambino || 0) * (prenotazione?.bambini || 0);
  //  const tot_bambini_online = parseFloat(tariffa_online_bambino || "0") * (prenotazione?.bambini || 0) + (prezzoServizioTipologiaBambino || 0) * (prenotazione?.bambini || 0);

  const [tariffa_online, set_tariffa_online] = useState("0");
  const [tariffa_online_bambino, set_tariffa_online_bambino] = useState("0");
  //const tot_adulti_online = parseFloat(tariffa_online || "0") * (prenotazione?.adulti || 0) + (prezzoServizioTipologiaAdulto || 0) * (prenotazione?.adulti || 0);

  const [tariffa_struttura, set_tariffa_struttura] = useState("0");
  const [tariffa_struttura_bambino, set_tariffa_struttura_bambino] = useState("0");

  const [tot_adulti_struttura, set_tot_adulti_struttura] = useState(0);
  const [tot_bambini_struttura, set_tot_bambini_struttura] = useState(0);

  const [tot_adulti_online, set_tot_adulti_online] = useState(0);
  const [tot_bambini_online, set_tot_bambini_online] = useState(0);

  const [tokenSpendibiliBambino, setTokenSpendibiliBambino] = useState(0);
  const [tokenSpendibiliAdulto, setTokenSpendibiliAdulto] = useState(0);

  const [paga_online, setPagaOnline] = useState(0);
  const [paga_struttura, setPagaStruttura] = useState(0);
  const [omaggio, setOmaggio] = useState(0);

  const [selectedPrefix, setSelectedPrefix] = useState("+39");

  const [isOpenModalPrezzi, setIsOpenModalPrezzi] = useState(false);
  const [prezzoShowModal, setPrezzoShowModal] = useState("");

  const handlePrefixChange = (event: ChangeEvent<HTMLSelectElement>) => {
    setSelectedPrefix(event.target.value);
  };

  const handlePhoneNumberChange = (event: ChangeEvent<HTMLInputElement>) => {
    const numericValue = event.target.value.replace(/[^0-9]/g, "");
    setSearchTel(numericValue);
  };

  const _calcTotal = () => {
    set_tot_adulti_online(
      parseFloat(tariffa_online || "0") * (prenotazione?.adulti || 0) + (prezzoServizioTipologiaAdulto || 0) * (prenotazione?.adulti || 0)
    );
    set_tot_bambini_online(
      parseFloat(tariffa_online_bambino || "0") * (prenotazione?.bambini || 0) + (prezzoServizioTipologiaBambino || 0) * (prenotazione?.bambini || 0)
    );

    let tAdulto = parseFloat(tariffa_online) - totale_adulti_servizi - prezzoServizioTipologiaAdultoToken;
    if (tAdulto < 0) tAdulto = 0;
    setTokenSpendibiliAdulto(tAdulto);
    let tBambino = parseFloat(tariffa_online_bambino) - totale_bambini_servizi - prezzoServizioTipologiaBambinoToken;
    if (tBambino < 0) tBambino = 0;
    setTokenSpendibiliBambino(tBambino);
  };

  const reqTa = parseFloat(tariffa_struttura) - totale_adulti_servizi;
  const reqTb = parseFloat(tariffa_struttura_bambino) - totale_bambini_servizi;
  var history = useHistory();
  const MySwal = withReactContent(Swal);

  function handleSelectChange(event: any) {
    setAgente(event.target.value);
  }

  function closeModalPrezzi() {
    setIsOpenModalPrezzi(false);
  }

  function openModalPrezzi(tipo: string) {
    setPrezzoShowModal(tipo);
    setIsOpenModalPrezzi(true);
  }

  useEffect(() => {
    if (nuovoCliente || nome != "") {
      if (sessionStorage.getItem("referente") !== null) {
        setAgente(sessionStorage.getItem("referente") || "");
      }
      const codiceSconto = sessionStorage.getItem("codice_sconto");

      if (codiceSconto !== null && codiceSconto !== "-") {
        setSconto(codiceSconto);
        handleSearchSconto(codiceSconto);
      }

    }
    if (clienteTesserato === true) {
      if (stato == "APERTO") {
        setStato(stato_tesserato);
        setStatoButtonFinish(stato_tesserato); // E' uguale si, ma mi abilita il tasto solo alla fine della ricerca del cliente!

        if (stato_tesserato == "CHIUSO") {
          setStato(stato_prenotazione);
          setStatoButtonFinish("CHIUSO");
        }
        return;
      }
    } else if (clienteAnagrafato === true) {
      if (clienteAnagrafatoPositivo === true) {
        if (stato == "APERTO") {
          setStato(stato_anagrafato_positivo);
          setStatoButtonFinish(stato_anagrafato_positivo); // E' uguale si, ma mi abilita il tasto solo alla fine della ricerca del cliente!

          if (stato_anagrafato_positivo == "CHIUSO") {
            setStato(stato_prenotazione);
            setStatoButtonFinish("CHIUSO");
          }

          return;
        }
      } else if (clienteAnagrafatoNegativo == true) {
        if (stato == "APERTO") {
          setStato(stato_anagrafato_negativo);
          setStatoButtonFinish(stato_anagrafato_negativo);

          if (stato_anagrafato_negativo == "CHIUSO") {
            setStato(stato_prenotazione);
            setStatoButtonFinish("CHIUSO");
          }
          return;
        }
      } else if (clienteAnagrafatoIntermedio == true) {
        if (stato == "APERTO") {
          setStato(stato_anagrafato_intermedio);
          setStatoButtonFinish(stato_anagrafato_intermedio);

          if (stato_anagrafato_intermedio == "CHIUSO") {
            setStato(stato_prenotazione);
            setStatoButtonFinish("CHIUSO");
          }
          return;
        }
      }
    }

    if (nuovoCliente) {
      if (clienteAnagrafatoPositivo === true) {
        setStato(stato_anagrafato_positivo);
        setStatoButtonFinish(stato_anagrafato_positivo); // E' uguale si, ma mi abilita il tasto solo alla fine della ricerca del cliente!

        if (stato_anagrafato_positivo == "CHIUSO") {
          setStato(stato_prenotazione);
          setStatoButtonFinish("CHIUSO");
        }

        return;
      } else if (clienteAnagrafatoNegativo == true) {
        setStato(stato_anagrafato_negativo);
        setStatoButtonFinish(stato_anagrafato_negativo);

        if (stato_anagrafato_negativo == "CHIUSO") {
          setStato(stato_prenotazione);
          setStatoButtonFinish("CHIUSO");
        }
        return;
      } else if (clienteAnagrafatoIntermedio == true) {
        setStato(stato_anagrafato_intermedio);
        setStatoButtonFinish(stato_anagrafato_intermedio);

        if (stato_anagrafato_intermedio == "CHIUSO") {
          setStato(stato_prenotazione);
          setStatoButtonFinish("CHIUSO");
        }
        return;
      }
    }
  }, [clienteAnagrafatoPositivo, clienteAnagrafatoNegativo, clienteAnagrafatoIntermedio, clienteTesserato, nuovoCliente]);

  useEffect(() => {
    if (statoFinale != "") {
      let labelPopup = "";
      switch (statoFinale) {
        case "RICHIESTA":
          if (metodoPagamentoScelto == "online") labelPopup = text_statoRichiestaOnline;
          else labelPopup = text_statoRichiestaStruttura;
          break;

        case "ATTESA":
          if (metodoPagamentoScelto == "online") labelPopup = text_statoAttesaOnline;
          else labelPopup = text_statoAttesaStruttura;
          break;

        case "CHIUSO":
          labelPopup = text_tipologiaSoldOut;
          break;

        default:
          break;
      }
      if (labelPopup != "") {
        MySwal.fire({
          title: <p>Attenzione</p>,
          html: <p>{labelPopup}</p>,
          confirmButtonColor: "#aa7832a8",
          icon: "error",
          //footer: 'Copyright 2018',
          didOpen: () => {
            //MySwal.clickConfirm()
          },
        }).then(() => {
          //return MySwal.fire(<p>Shorthand works too</p>)
        });
      }
    }
  }, [statoFinale]);

  /* Recupero gli agenti  e setto lo stato*/
  useEffect(() => {
    setPagaOnline(arrayScelto?.paga_online || 0);
    setPagaStruttura(arrayScelto?.paga_struttura || 0);
    getAgents();
    setStato(stato_prenotazione);

    if (stato_prenotazione != "APERTO" && stato_prenotazione != "") {
      setStatoButtonFinish(stato_prenotazione);
    } else {
      setStatoButtonFinish("");
    }
    const t: never[] = [];
    if (prenotazione?.metodoPagamento != "") setMetodoPagamento(prenotazione?.metodoPagamento || "");

    setServiziSelected(prenotazione?.serviziSelected || ([] as ServizioType[]));
    set_tariffa_online(arrayScelto?.tariffa_online || "0");
    set_tariffa_online_bambino(arrayScelto?.tariffa_online_bambino || "0");
    setTotaleAdultiServizi(prenotazione?.totale_adulti_servizi || 0);
    setTotaleBambiniServizi(prenotazione?.totale_bambini_servizi || 0);
    setTokenSpendibiliAdulto(0);

    set_tot_adulti_struttura(
      parseFloat(tariffa_struttura || "0") * (prenotazione?.adulti || 0) + (prezzoServizioTipologiaAdulto || 0) * (prenotazione?.adulti || 0)
    );
    set_tot_bambini_struttura(
      parseFloat(tariffa_struttura_bambino || "0") * (prenotazione?.bambini || 0) +
      (prezzoServizioTipologiaBambino || 0) * (prenotazione?.bambini || 0)
    );
    set_tariffa_struttura(arrayScelto?.tariffa_struttura || "0");
    set_tariffa_struttura_bambino(arrayScelto?.tariffa_struttura_bambino || "0");

    _calcTotal();

    if (sessionStorage.getItem("referente") !== null) {
      // setAgente(sessionStorage.getItem("referente") || "");
    }

    if (sessionStorage.getItem("codice_sconto") !== null) {
      // setSconto();
    } else setSconto("");
  }, [prenotazione]);

  useEffect(() => {
    // setSconto("");

    setServiziSelected(prenotazione?.serviziSelected || ([] as ServizioType[]));
    set_tariffa_online(arrayScelto?.tariffa_online || "0");
    set_tariffa_online_bambino(arrayScelto?.tariffa_online_bambino || "0");
    setTotaleAdultiServizi(prenotazione?.totale_adulti_servizi || 0);
    setTotaleBambiniServizi(prenotazione?.totale_bambini_servizi || 0);
    setTokenSpendibiliAdulto(0);

    if (omaggio == 1) {
      set_tot_adulti_struttura(0);
      set_tot_bambini_struttura(0);
    } else {
      set_tot_adulti_struttura(
        parseFloat(tariffa_struttura || "0") * (prenotazione?.adulti || 0) + (prezzoServizioTipologiaAdulto || 0) * (prenotazione?.adulti || 0)
      );
      set_tot_bambini_struttura(
        parseFloat(tariffa_struttura_bambino || "0") * (prenotazione?.bambini || 0) +
        (prezzoServizioTipologiaBambino || 0) * (prenotazione?.bambini || 0)
      );
    }

    _calcTotal();
  }, [metodoPagamentoScelto]);

  const getAgents = () => {
    fetch(`https://booking.jooba.it/agents?evento_id=${evento_id}`, {
      method: "GET",
      headers: {},
    })
      .then((response) => response.json())
      .then(async (response) => {
        setReferents(response);
        //   if(sel!="") setAgente(sel)
      })
      .catch((error) => console.log(error));
  };

  /* Recupero gli agenti e setto lo stato*/

  useEffect(() => {
    setPrenotazione(location.state);
  }, [location]);

  useEffect(() => {
    _calcTotal();
    if (firstOpen == 0) {
      const updatedData = [...serviziSelected];

      updatedData.forEach((element) => {
        element.price_adulti = tariffa_online;
        element.price_bambino = tariffa_online_bambino;
      });

      setServiziSelected(updatedData);
    } else setFirstOpen(1);
  }, [tariffa_online, tariffa_online_bambino]);

  /* Rierca cliente per numero di telefono */
  const handleSearch = () => {
    const accAdulto = arrayScelto?.importo_acconto || 0;
    const accBambino = arrayScelto?.importo_acconto_bambini || 0;
    let tot = adulti * accAdulto;
    tot += bambini * accBambino;

    if (telefonoSearch == "") {
      MySwal.fire({
        title: <p>Attenzione</p>,
        html: <p>Attenzione il campo telefono è vuoto!</p>,
        confirmButtonColor: "#aa7832a8",
        icon: "error",
        //footer: 'Copyright 2018',
        didOpen: () => {
          //MySwal.clickConfirm()
        },
      }).then(() => {
        //return MySwal.fire(<p>Shorthand works too</p>)
      });
      return;
    }

    setClienteByTelefono(telefonoSearch, tot);
  };

  /* Rierca codice sconto */
  const handleSearchSconto = (sel = "") => {
    if (scontoCode != "") {
      MySwal.fire({
        title: <p>Attenzione</p>,
        html: <p>Hai già un codice applicato</p>,
        confirmButtonColor: "#aa7832a8",
        icon: "error",
        //footer: 'Copyright 2018',
        didOpen: () => {
          //MySwal.clickConfirm()
        },
      }).then(() => {
        //return MySwal.fire(<p>Shorthand works too</p>)
      });
      return;
    }
    if (sconto != "" || sel != "") {
      const par = sconto != "" ? sconto : sel;
      fetch(
        `https://booking.jooba.it/sconto?codice=${par}&customer_id=${id}&evento_id=${evento_id}&tipologia_id=${tipologia_id}&persone=${adulti + bambini
        }`,
        {
          method: "GET",
          headers: {},
        }
      )
        .then((response) => response.json())
        .then(async (response) => {
          if (response.status == 1) {
            if (response.data.all_customer == 1) {
              setScontoCode(sconto);
              setOmaggio(response.data.omaggio);
              MySwal.fire({
                title: <p>Congratulazioni</p>,
                html: <p>Codice sconto inserito con successo!</p>,
                confirmButtonColor: "#aa7832a8",
                icon: "success",
                //footer: 'Copyright 2018',
                didOpen: () => {
                  //MySwal.clickConfirm()
                },
              }).then(() => {
                //return MySwal.fire(<p>Shorthand works too</p>)
              });
              setClienteAnagrafatoPositivo(true);
              if (response.data.importo_adulti != 0) set_tariffa_online(response.data.importo_adulti);
              if (response.data.importo_bambini != 0) set_tariffa_online_bambino(response.data.importo_bambini);
              if (response.data.paga_struttura == 1) {
                setPagaStruttura(1);
                if (response.data.importo_adulti != 0) set_tariffa_online(response.data.importo_adulti);
                if (response.data.importo_bambini != 0) set_tariffa_online_bambino(response.data.importo_bambini);
              }

              if (response.data.omaggio == 1) {
                set_tariffa_online("0");
                set_tariffa_online_bambino("0");
                setPagaOnline(0);
                const newServSel = serviziSelected.map((servizio, index) => ({
                  ...servizio,
                  price_adulti: "0.00",
                  price_bambino: "0.00",
                }));
                setServiziSelected(newServSel);
              }
            } else if (clienteAnagrafatoPositivo) {
              setScontoCode(sconto);
              setOmaggio(response.data.omaggio);
              MySwal.fire({
                title: <p>Congratulazioni</p>,
                html: <p>Codice sconto inserito con successo!</p>,
                confirmButtonColor: "#aa7832a8",
                icon: "success",
                //footer: 'Copyright 2018',
                didOpen: () => {
                  //MySwal.clickConfirm()
                },
              }).then(() => {
                //return MySwal.fire(<p>Shorthand works too</p>)
              });
              if (response.data.omaggio == 1) {
                set_tariffa_online("0");
                set_tariffa_online_bambino("0");
                setPagaOnline(0);
                const newServSel = serviziSelected.map((servizio) => ({
                  ...servizio,
                  price_adulti: "0.00",
                  price_bambino: "0.00",
                }));
                setServiziSelected(newServSel);
              } else {
                if (response.data.importo_adulti != 0) set_tariffa_online(response.data.importo_adulti);
                if (response.data.importo_bambini != 0) set_tariffa_online_bambino(response.data.importo_bambini);
              }
            } else {
              setScontoCode("");
            }
          } else {
            MySwal.fire({
              title: <p>Attenzione</p>,
              html: <p>Attenzione il codice non è valido!</p>,
              confirmButtonColor: "#aa7832a8",
              icon: "error",
              //footer: 'Copyright 2018',
              didOpen: () => {
                //MySwal.clickConfirm()
              },
            }).then(() => {
              //return MySwal.fire(<p>Shorthand works too</p>)
            });
          }
        })
        .catch((error) => console.log(error));
    } else {
      MySwal.fire({
        title: <p>Attenzione</p>,
        html: <p>Attenzione il campo sconto è vuoto!</p>,
        confirmButtonColor: "#aa7832a8",
        icon: "error",
        //footer: 'Copyright 2018',
        didOpen: () => {
          //MySwal.clickConfirm()
        },
      }).then(() => {
        //return MySwal.fire(<p>Shorthand works too</p>)
      });
    }
  };

  function setClienteByTelefono(telefonoSearch: any, tot: number) {
    fetch("https://booking.jooba.it/get_customer", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ 'telefono': telefonoSearch, 'prefisso': selectedPrefix }),
    })
      .then((response) => {
        return response.json();
      })
      .then(async (response) => {
        if (response.cognome == undefined) {
          MySwal.fire({
            title: <p>Attenzione</p>,
            html: <p>Sembra che il numero inserito non sia presente nel sistema, si prega di riprovare</p>,
            confirmButtonColor: "#aa7832a8",
            icon: "error",
            footer: "Se non hai mai effettuato una prenotazione, procedi con la sezione successiva",
            didOpen: () => {
              //MySwal.clickConfirm()
            },
          }).then((result) => {
            if (result.isConfirmed) {
              handleClientType('new')
              setTrovato(false);
            }
          })
        } else {
          handleClientType('old')
          setTrovato(true);
          setAcconto(0);
          setTextAcconto("");
          setId(response.id);
          setSelectedPrefix(response.prefisso ?? '+39');
          setSearchTel(response.telefono);
          setNome(response.nome.slice(0, -5) + "*****");
          setCognome(response.cognome.slice(0, -5) + "*****");
          if (response.email == "") {
            setEmailMancante(true);
            setNome(response.nome.slice(0, -5) + "*****");
            setCognome(response.cognome.slice(0, -5) + "*****");
          } else {
            setEmailMancante(false);
            setEmail(response.email.slice(0, -15) + "*****");
          }
          if (response.abilitato == 0) {
            setClienteAnagrafato(false);
            setClienteTesserato(false);
            setClienteNotAbilitato(true);

            MySwal.fire({
              title: <p>Attenzione</p>,
              html: <p>{text_utenteAbilitato}</p>,
              icon: "error",
              //footer: 'Copyright 2018',
              didOpen: () => {
                //MySwal.clickConfirm()
              },
            }).then(() => {
              //return MySwal.fire(<p>Shorthand works too</p>)
            });
            return;
          } else if (response.tesserato == 1) {
            setClienteAnagrafato(true);
            setClienteTesserato(true);
            setClienteNotAbilitato(true);
            setClienteAnagrafatoPositivo(true);
            const result = acconto_tipologia?.filter((acc) => acc == "tesserato");
            if (result) {
              if (result.length > 0) {
                setAcconto(1);
                setImportoAcconto(tot);
                setTextAcconto(text_richiestaCaparra);
                MySwal.fire({
                  title: <p>Attenzione</p>,
                  html: <p>{text_swalCaparra}</p>,
                  confirmButtonColor: "#aa7832a8",
                  icon: "warning",
                  //footer: 'Copyright 2018',
                  didOpen: () => {
                    //MySwal.clickConfirm()
                  },
                }).then(() => {
                  //return MySwal.fire(<p>Shorthand works too</p>)
                });
              }
            }
          } else if (response.abilitato == 1 && response.tesserato == 0) {
            setClienteTesserato(false);
            setClienteNotAbilitato(true);

            if (response.val_media != null && response.val_media != "" && response.val_media != 0) {
              if (response.val_media >= parseFloat(val_pren)) {
                //Anagrafato positivo
                const result = acconto_tipologia?.filter((acc) => acc == "anagrafato_positivo");
                if (result) {
                  if (result.length > 0) {
                    setImportoAcconto(tot);
                    setAcconto(1);
                    setTextAcconto(text_richiestaCaparra);
                    MySwal.fire({
                      title: <p>Attenzione</p>,
                      html: <p>{text_swalCaparra}</p>,
                      confirmButtonColor: "#aa7832a8",
                      icon: "warning",
                      //footer: 'Copyright 2018',
                      didOpen: () => {
                        //MySwal.clickConfirm()
                      },
                    }).then(() => {
                      //return MySwal.fire(<p>Shorthand works too</p>)
                    });
                  }
                }

                setClienteAnagrafatoPositivo(true);
                setClienteAnagrafatoNegativo(false);
                setClienteAnagrafatoIntermedio(false);
              } else if (response.val_media <= parseFloat(val_pren_inferiore)) {
                //Anagrafato negativo
                const result = acconto_tipologia?.filter((acc) => acc == "anagrafato_negativo");
                if (result) {
                  if (result.length > 0) {
                    setImportoAcconto(tot);
                    setAcconto(1);
                    setTextAcconto(text_richiestaCaparra);
                    MySwal.fire({
                      title: <p>Attenzione</p>,
                      html: <p>{text_swalCaparra}</p>,
                      confirmButtonColor: "#aa7832a8",
                      icon: "warning",
                      //footer: 'Copyright 2018',
                      didOpen: () => {
                        //MySwal.clickConfirm()
                      },
                    }).then(() => {
                      //return MySwal.fire(<p>Shorthand works too</p>)
                    });
                  }
                }

                setClienteAnagrafatoPositivo(false);
                setClienteAnagrafatoNegativo(true);
                setClienteAnagrafatoIntermedio(false);
              } else if (response.val_media > parseFloat(val_pren_inferiore) && response.val_media < parseFloat(val_pren)) {
                //Anagrafato intermedio

                const result = acconto_tipologia?.filter((acc) => acc == "anagrafato_intermedio");
                if (result) {
                  if (result.length > 0) {
                    setImportoAcconto(tot);
                    setAcconto(1);
                    setTextAcconto(text_richiestaCaparra);
                    MySwal.fire({
                      title: <p>Attenzione</p>,
                      html: <p>{text_swalCaparra}</p>,
                      confirmButtonColor: "#aa7832a8",
                      icon: "warning",
                      //footer: 'Copyright 2018',
                      didOpen: () => {
                        //MySwal.clickConfirm()
                      },
                    }).then(() => {
                      //return MySwal.fire(<p>Shorthand works too</p>)
                    });
                  }
                }

                setClienteAnagrafatoPositivo(false);
                setClienteAnagrafatoNegativo(false);
                setClienteAnagrafatoIntermedio(true);
              }
            } else {
              //Nuovo cliente
              setClienteAnagrafato(false);
              setClienteTesserato(false);
              setNuovoCliente(true);

              if (new_customer == "0") {
                setAbilitoCompilazioneNewClient(false);
                setViewCart(false);
                if (stato_prenotazione == "APERTO") {
                  setStatoButtonFinish("");
                }
                MySwal.fire({
                  title: <p>Attenzione</p>,
                  html: <p>{text_new_customer}</p>,
                  confirmButtonColor: "#aa7832a8",
                  icon: "error",
                  //footer: 'Copyright 2018',
                  didOpen: () => {
                    //MySwal.clickConfirm()
                  },
                }).then(() => {
                  //return MySwal.fire(<p>Shorthand works too</p>)
                });
              } else {
                setAbilitoCompilazioneNewClient(true);
                setViewCart(true);

                //Verifico acconto
                const result = acconto_tipologia?.filter((acc) => acc == "nuovo_cliente");
                if (result) {
                  if (result.length > 0) {
                    setAcconto(1);
                    setTextAcconto(text_richiestaCaparra);
                    MySwal.fire({
                      title: <p>Attenzione</p>,
                      html: <p>{text_swalCaparra}</p>,
                      confirmButtonColor: "#aa7832a8",
                      icon: "warning",
                      //footer: 'Copyright 2018',
                      didOpen: () => {
                        //MySwal.clickConfirm()
                      },
                    }).then(() => {
                      //return MySwal.fire(<p>Shorthand works too</p>)
                    });
                  }
                }

                if (stato_prenotazione == "APERTO") {
                  setClienteAnagrafato(false);
                  setClienteTesserato(false);
                  setClienteNotAbilitato(true);

                  setStatoButtonFinish(stato_nuovo_cliente);
                  setStato(stato_nuovo_cliente);

                  if (stato_nuovo_cliente == "CHIUSO") {
                    setStato(stato_prenotazione);
                    setStatoButtonFinish("CHIUSO");
                  }
                } else setStatoButtonFinish(stato_prenotazione);
              }
            }
          }
        }

        setShowFieldAnagrafica(true);

      })
      .catch((err) => console.log(err));
  }

  /* Rierca cliente per numero di telefono */
  /* Registrazione del cliente nuovo */
  const handleSubmit = (pagamento: string) => {
    if (nome == "") {
      MySwal.fire({
        title: <p>Attenzione</p>,
        html: <p>Il campo nome è obbligatorio</p>,
        confirmButtonColor: "#aa7832a8",
        icon: "error",
        //footer: 'Copyright 2018',
        didOpen: () => {
          //MySwal.clickConfirm()
        },
      }).then(() => {
        //return MySwal.fire(<p>Shorthand works too</p>)
      });
      return;
    } else if (cognome == "") {
      MySwal.fire({
        title: <p>Attenzione</p>,
        html: <p>Il cognome è obbligatorio</p>,
        confirmButtonColor: "#aa7832a8",
        icon: "error",
        //footer: 'Copyright 2018',
        didOpen: () => {
          //MySwal.clickConfirm()
        },
      }).then(() => {
        //return MySwal.fire(<p>Shorthand works too</p>)
      });
      return;
    } else if (email == "") {
      MySwal.fire({
        title: <p>Attenzione</p>,
        html: <p>Il email è obbligatorio</p>,
        icon: "error",
        //footer: 'Copyright 2018',
        didOpen: () => {
          //MySwal.clickConfirm()
        },
      }).then(() => {
        //return MySwal.fire(<p>Shorthand works too</p>)
      });
      return;
    } else if (telefonoSearch == "") {
      MySwal.fire({
        title: <p>Attenzione</p>,
        html: <p>Il telefono è obbligatorio</p>,
        confirmButtonColor: "#aa7832a8",
        icon: "error",
        //footer: 'Copyright 2018',
        didOpen: () => {
          //MySwal.clickConfirm()
        },
      }).then(() => {
        //return MySwal.fire(<p>Shorthand works too</p>)
      });
      return;
    } else if (!/^[a-zA-Z0-9\.\_\-]+@[a-zA-Z0-9]+\.[A-Za-z]+$/.test(email) && id == "") {
      MySwal.fire({
        title: <p>Attenzione</p>,
        html: <p>Il campo email non è valido</p>,
        icon: "error",
        //footer: 'Copyright 2018',
        didOpen: () => {
          //MySwal.clickConfirm()
        },
      }).then(() => {
        //return MySwal.fire(<p>Shorthand works too</p>)
      });
      return;
    } else if (telefonoSearch.length < 9) {
      MySwal.fire({
        title: <p>Attenzione</p>,
        html: <p>Il campo telefono non è valido</p>,
        confirmButtonColor: "#aa7832a8",
        icon: "error",
        //footer: 'Copyright 2018',
        didOpen: () => {
          //MySwal.clickConfirm()
        },
      }).then(() => {
        //return MySwal.fire(<p>Shorthand works too</p>)
      });
      return;
    } else if (referente_required == 1 && agent_id == "") {
      MySwal.fire({
        title: <p>Attenzione</p>,
        html: <p>E' necessario inserire il referente per continuare!</p>,
        confirmButtonColor: "#aa7832a8",
        icon: "error",
        //footer: 'Copyright 2018',
        didOpen: () => {
          //MySwal.clickConfirm()
        },
      }).then(() => {
        //return MySwal.fire(<p>Shorthand works too</p>)
      });
      return;
    } else if (!/^[a-zA-Z0-9\.\_\-]+@[a-zA-Z0-9]+\.[A-Za-z]+$/.test(email) && emailmancante === true) {
      MySwal.fire({
        title: <p>Attenzione</p>,
        html: <p>Il campo email non è valido</p>,
        icon: "error",
        //footer: 'Copyright 2018',
        didOpen: () => {
          //MySwal.clickConfirm()
        },
      }).then(() => {
        //return MySwal.fire(<p>Shorthand works too</p>)
      });
      return;
    }

    if (cond3 == false && nuovoCliente == true) {
      MySwal.fire({
        title: <p>Attenzione</p>,
        html: <p>Bisogna necessariamente accettare Privacy & Cookies Policy</p>,
        confirmButtonColor: "#aa7832a8",
        icon: "error",
        //footer: 'Copyright 2018',
        didOpen: () => {
          //MySwal.clickConfirm()
        },
      }).then(() => {
        //return MySwal.fire(<p>Shorthand works too</p>)
      });
      return;
    }
    let totale = 0;
    if (metodoPagamentoScelto.toUpperCase() == "STRUTTURA")
      totale = tot_adulti_struttura + tot_bambini_struttura + quota_una_tantum + costoProdottoOpzione;
    else totale = tot_adulti_online + tot_bambini_online + quota_una_tantum + costoProdottoOpzione;

    const jsonPost = {
      customer_id: id,
      nome,
      cognome,
      prefisso: selectedPrefix,
      telefono: telefonoSearch,
      email,
      evento_id,
      acconto,
      tipologia_id,
      adulti,
      bambini,
      bambiniBaby,
      pagamento: pagamento.toUpperCase(),
      metodo: metodoPagamentoScelto.toUpperCase(),
      cond1,
      cond3,
      agent_id,
      data: dataEvento,
      tipo_id,
      area,
      area_id,
      ta: reqTa,
      tb: reqTb,
      animali: animali === true ? 1 : 0,
      options,
      totale: totale,
      sconto,
      omaggio,
    };

    sessionStorage.clear();

    fetch("https://booking.jooba.it/store_booking_update", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(jsonPost),
    })
      .then((response) => response.json())
      .then(async (response) => {
        if (acconto == 1) {
          if (response.url != "") {
            window.location.href = response.url;
          } else {
            MySwal.fire({
              title: <p>Attezione</p>,
              html: <p>Errore imprevisto si prega di contattare la direzione, errore 001X</p>,
              icon: "success",
              confirmButtonColor: "#aa7832a8",
              confirmButtonText: "Ok",
              didOpen: () => {
                //MySwal.clickConfirm()
              },
            }).then((result) => {
              if (result.isConfirmed) {
                history.push("/");
              }
            });
          }
        } else {
          if (response.res == 1) {
            //prenotazione creata
            if (response.ticket != "") {
              let link = response.ticket;

              MySwal.fire({
                title: <p>Grazie</p>,
                html: <p>{response.msg}</p>,
                icon: "success",
                confirmButtonColor: "#aa7832a8",
                confirmButtonText: '<a href="' + link + '" target="_blank">Scarica il ticket!</a>',
                didOpen: () => {
                  //MySwal.clickConfirm()
                },
              }).then((result) => {
                if (result.isConfirmed === true) {
                  history.push("/");
                }
              });
            } else {
              if (response.url != "") {
                window.location.href = response.url;
              } else {
                MySwal.fire({
                  title: <p>Grazie</p>,
                  html: <p>{response.msg}</p>,
                  confirmButtonColor: "#aa7832a8",
                  icon: "success",
                  //footer: 'Copyright 2018',
                  didOpen: () => {
                    //MySwal.clickConfirm()
                  },
                }).then(() => {
                  history.push("/");
                });
              }
            }
          } else if (response == -1) {
            MySwal.fire({
              title: <p>Attenzione</p>,
              html: <p>{text_prenotazioneEsistente}</p>,
              icon: "warning",
              confirmButtonColor: "#aa7832a8",
              footer: "Se non trovi l'email con il ticket, controlla nella SPAM!",
              didOpen: () => {
                //MySwal.clickConfirm()
              },
            }).then(() => {
              //return MySwal.fire(<p>Shorthand works too</p>)
              history.push("/");
            });
          }
        }
      })
      .catch((err) => console.log(err));
  };
  /* Fine: Registrazione del cliente nuovo */

  /* Tipo di cliente iniziale */
  const handleClientType = (tipo: string) => {
    setStato(stato_prenotazione);
    setStatoButtonFinish("");
    setAcconto(0);
    setTextAcconto("");
    setViewCart(true);
    setAbilitoCompilazioneNewClient(true);
    setCognome("");
    setNome("");
    setEmail("");
    if (tipo == "old") {
      setPrivacy(true);
      setEmailMancante(false);
      if (stato_prenotazione == "APERTO") {
        setStatoButtonFinish("");
      } else setStatoButtonFinish(stato_prenotazione);

      setClienteAnagrafato(true);
      setClienteTesserato(false);
      setNuovoCliente(false);
    } else if (tipo == "new") {
      setPrivacy(false);
      setTrovato(false);
      setEmailMancante(false);
      setClienteAnagrafato(false);
      setClienteTesserato(false);
      setNuovoCliente(true);

      if (new_customer == "0") {
        setAbilitoCompilazioneNewClient(false);
        setViewCart(false);
        if (stato_prenotazione == "APERTO") {
          setStatoButtonFinish("");
        }
        MySwal.fire({
          title: <p>Attenzione</p>,
          html: <p>{text_new_customer}</p>,
          confirmButtonColor: "#aa7832a8",
          icon: "error",
          //footer: 'Copyright 2018',
          didOpen: () => {
            //MySwal.clickConfirm()
          },
        }).then(() => {
          //return MySwal.fire(<p>Shorthand works too</p>)
        });
      } else {
        setAbilitoCompilazioneNewClient(true);
        setViewCart(true);

        //Verifico acconto
        const result = acconto_tipologia?.filter((acc) => acc == "nuovo_cliente");
        if (result) {
          if (result.length > 0) {
            setAcconto(1);
            setTextAcconto(text_richiestaCaparra);
            MySwal.fire({
              title: <p>Attenzione</p>,
              html: <p>{text_swalCaparra}</p>,
              confirmButtonColor: "#aa7832a8",
              icon: "warning",
              //footer: 'Copyright 2018',
              didOpen: () => {
                //MySwal.clickConfirm()
              },
            }).then(() => {
              //return MySwal.fire(<p>Shorthand works too</p>)
            });
          }
        }

        if (stato_prenotazione == "APERTO") {
          setClienteAnagrafato(false);
          setClienteTesserato(false);
          setClienteNotAbilitato(true);

          setStatoButtonFinish(stato_nuovo_cliente);
          setStato(stato_nuovo_cliente);

          if (stato_nuovo_cliente == "CHIUSO") {
            setStato(stato_prenotazione);
            setStatoButtonFinish("CHIUSO");
          }
        } else setStatoButtonFinish(stato_prenotazione);
      }
    }
  };
  /* Fine di cliente iniziale */

  const richiestaCliente = () => {
    return (
      <div className="w-full flex flex-col rounded border border-neutral-200 space-y-6 p-4">
        {/* {clienteAnagrafato === true && ( */}
        <div>
          <span className="text-neutral-400">Inserisci il numero di telefono</span>
          <div className="flex space-x-5 mt-4 mb-4">
            <div className="flex-auto w-60 space-y-1">
              <PhoneInput
                prefixOptions={europeanPrefixes}
                selectedPrefix={selectedPrefix}
                phoneNumber={telefonoSearch}
                onPrefixChange={handlePrefixChange}
                onPhoneNumberChange={handlePhoneNumberChange}
              />
            </div>
          </div>
          <div className="mb-4">
            <ButtonPrimary className="w-full" sizeClass="py-2 px-12" onClick={() => handleSearch()}>
              Carica dati
            </ButtonPrimary>
          </div>
        </div>
        {showFieldAnagrafica &&
          <div>
            <hr className="mt-4 mb-4" />
            <div className="flex space-x-5">
              <div className="flex-1 space-y-1">
                <Label>Nome</Label>
                <Input type={"text"} value={nome} onInput={(e) => setNome((e.target as HTMLInputElement).value)} />
              </div>
              <div className="flex-1 space-y-1">
                <Label>Cognome</Label>
                <Input type={"text"} value={cognome} onInput={(e) => setCognome((e.target as HTMLInputElement).value)} />
              </div>
            </div>
            <div className="flex space-x-5 mt-4 mb-4">
              <div className="flex-1 space-y-1">
                <Label>Email</Label>
                <Input type={"email"} value={email} onInput={(e) => setEmail((e.target as HTMLInputElement).value)} />
              </div>
            </div>
            {(referente == 1 && referents.length > 0) && (
              <>
                <Label>Referente</Label>
                <Select className="mt-4" value={agent_id} onChange={handleSelectChange}>
                  <option>Seleziona referente</option>
                  {referents.map((x: any) => (
                    <option value={x.id}>
                      {x.cognome} {x.name}
                    </option>
                  ))}
                </Select>
              </>
            )}
            <div className="flex space-x-5 mt-6">
              <Checkbox
                className="w-full"
                onChange={(value) => {
                  setPrivacy(true);
                }}
                checked={cond3}
                label={
                  <div>
                    <span>Ho letto e accetto la</span>
                    <Link className="text-primary-6000" to={"/privacy-policy"}>
                      {" "}
                      Privacy & Cookies Policy
                    </Link>
                  </div>
                }
                name="cond3"
              />
            </div>
            <div className="flex space-x-5 mt-6">
              <Checkbox
                className="w-full"
                onChange={(value) => {
                  if (cond1 == false) {
                    setCond1(true);
                  } else setCond1(false);
                }}
                checked={cond1}
                label={
                  <div>
                    <span>Voglio ricevere aggiornamenti sugli eventi Umoya</span>
                  </div>
                }
                name="cond1"
              />
            </div>
          </div>
        }

        <div className="flex flex-col space-y-3">
          <hr className="py-2" />
          <div className="flex">
            <h3 className="text-2xl font-semibold text-primary-6000">Metodo di pagamento</h3>
          </div>
          <div>
            <span className="text-sm">
              <i className="la la-euro text-lg mr-2"></i>Metodo di pagamento:{" "}
              <span className="text-sm" style={{ color: "red" }}>
                {metodoPagamentoScelto}
              </span>
            </span>
          </div>

          <span className="text-neutral-400">Se hai un codice sconto o evento inseriscilo qui altrimenti prosegui</span>
          <div className="flex space-x-5 mt-4 mb-4">
            <div className="flex-auto w-60 space-y-1">
              <Input placeholder="" type={"text"} value={sconto} onInput={(e) => setSconto((e.target as HTMLInputElement).value)} />
            </div>
            <div className="mb-4">
              <ButtonPrimary className="w-full" sizeClass="py-2 px-12" onClick={() => handleSearchSconto()}>
                Applica codice
              </ButtonPrimary>
            </div>
          </div>

          {paga_online == 1 && paga_struttura == 1 && (
            <>
              <div className="flex">
                <h4 className="text-sm font-semibold" style={{ fontStyle: "italic" }}>
                  Puoi cambiare il metodo di pagamento qui:
                </h4>
              </div>
              <div className="flex flex-row pt-4" style={{ justifyContent: "space-between" }}>
                {renderInputMetodoDiPagamentoOnline()}
                {renderInputMetodoDiPagamentoStruttura()}
              </div>
            </>
          )}
          <div className="flex flex-row text-center">
            <span className="text-xs">
              Prodendo si accettano automaticamente i termini{" "}
              <span className="text-primary-6000 text-xs" style={{ textDecoration: "underline" }}>
                <Link target={"_blanck"} to={{ pathname: `/cancellazione_rimborso/${evento_id}` }}>
                  cancellazione e rimborso
                </Link>
              </span>
            </span>
          </div>
          <div className="mt-5">
            {renderConfermaEPagaOnline()}
            {renderConfermaEPagaStruttura()}
          </div>

          {(!clienteAnagrafato && !nuovoCliente) ||
            (statoFinale == "" && (
              <div className="pt-4">
                <ButtonSecondary
                  className="w-full"
                  onClick={() => {
                    return;
                  }}
                >
                  Per continuare compila i tuoi dati
                </ButtonSecondary>
              </div>
            ))}

          {statoFinale == "APERTO" && metodoPagamentoScelto == "online" && omaggio == 0 && (
            <div className="pt-4">
              <ButtonPrimary
                className="w-full"
                onClick={() => {
                  handleSubmit("Online");
                }}
              >
                Conferma e paga
              </ButtonPrimary>
            </div>
          )}

          {statoFinale == "APERTO" && (metodoPagamentoScelto == "struttura" || omaggio == 1) && (
            <div className="pt-4">
              <ButtonPrimary
                className="w-full"
                onClick={() => {
                  handleSubmit("Struttura");
                }}
              >
                Conferma
              </ButtonPrimary>
            </div>
          )}
          {statoFinale == "CHIUSO" && (
            <div className="pt-4">
              <div className="flex justify-between text-neutral-6000 dark:text-neutral-300 text-center mb-2">
                <span className="text-sm" style={{ color: "red", fontStyle: "italic" }}>
                  <b>{text_tipologiaSoldOut}</b>
                </span>
              </div>
            </div>
          )}
          {statoFinale == "RICHIESTA" && (
            <div className="pt-4">
              <div className="flex justify-between text-neutral-6000 dark:text-neutral-300 text-center mb-2">
                <span className="text-sm" style={{ color: "red", fontStyle: "italic" }}>
                  <b>{metodoPagamentoScelto == "online" ? text_statoRichiestaOnline : text_statoRichiestaStruttura}</b>
                </span>
              </div>
              <ButtonPrimary
                className="w-full"
                onClick={() => {
                  handleSubmit("Richiesta");
                }}
              >
                Invia richiesta
              </ButtonPrimary>
            </div>
          )}
          {statoFinale == "ATTESA" && (
            <div className="pt-4">
              <div className="flex justify-between text-neutral-6000 dark:text-neutral-300 text-center mb-2">
                <span className="text-sm" style={{ color: "red", fontStyle: "italic" }}>
                  <b>{metodoPagamentoScelto == "online" ? text_statoAttesaOnline : text_statoAttesaStruttura}</b>
                </span>
              </div>
              <ButtonPrimary
                className="w-full"
                onClick={() => {
                  handleSubmit("Attesa");
                }}
              >
                Inserisciti in lista di attesa
              </ButtonPrimary>
            </div>
          )}
        </div>
        {accordionMain()}
      </div>
    );
  };

  const renderSidebar = () => {
    return (
      <div className="w-full flex flex-col rounded border border-neutral-200 p-4 mb-10 space-y-2">
        <h3 className="text-2xl font-semibold text-primary-6000">Dettaglio Prezzi</h3>
        <hr />
        <div className="space-y-4">
          <div className="flex justify-between text-neutral-6000 dark:text-neutral-300">
            {stato == "RICHIESTA" && <span>Stai effettuando una richiesta di prenotazione per: </span>}
            {stato == "ATTESA" && <span>Ti stai inserendo in una lista di attesa per: </span>}
            {stato == "APERTO" && <span>Stai effettuando una prenotazione per: </span>}
          </div>
          <div className="flex justify-between text-neutral-6000 dark:text-neutral-300">
            <span className="font-bold">{tipologia}</span>
          </div>
          {note_orario === null ? (
            <div className="flex justify-between text-neutral-6000 dark:text-neutral-300">
              <span style={{ textDecoration: "underline", color: "green" }}>
                {day} {dataEvento}
              </span>
            </div>
          ) : (
            <div className="flex justify-between text-neutral-6000 dark:text-neutral-300">
              <span style={{ textDecoration: "underline", color: "green" }}>
                {day} {dataEvento} - {note_orario}
              </span>
            </div>
          )}
        </div>

        {renderRiassuntoPrezzi()}
        {/* {metodoPagamentoScelto == "online" && renderRiassuntoPrezziOnline()}

        {metodoPagamentoScelto == "struttura" && renderRiassuntoPrezziStruttura()} */}

        {clienteAbilitato === true && sezioneButtonPagamento()}
      </div>
    );
  };

  const accordionMain = () => {
    return (
      <div className="space-y-2">
        <hr className="pt-5 pb-2" />
        {note_generali != null && note_generali != "" && (
          <div>
            <FlightCardNote
              data={{
                titolo: title_note_generali,
                descrizione: note_generali.replace("$TA", ta).replace("$TB", tb),
                backColor: color_note_generali,
                open: open_accordion_note_generali,
              }}
            />
          </div>
        )}
        {accordion_acconto != null && accordion_acconto != "" && (
          <div>
            <FlightCardNote
              data={{
                titolo: accordion_acconto,
                descrizione: testo_accordion_acconto,
                backColor: color_accordion_acconto,
                open: open_accordion_acconto,
              }}
            />
          </div>
        )}
        {note_token != "" && (
          <div>
            <FlightCardNote
              data={{ titolo: title_note_token, descrizione: note_token, backColor: color_title_note_token, open: open_accordion_note_token }}
            />
          </div>
        )}
        {stato == "RICHIESTA" &&
          accordion_richiestadiprenotazione != "" &&
          accordion_richiestadiprenotazione != null &&
          testo_accordion_richiestadiprenotazione != "" &&
          testo_accordion_richiestadiprenotazione != null && (
            <div>
              <FlightCardNote
                data={{
                  titolo: accordion_richiestadiprenotazione,
                  descrizione: testo_accordion_richiestadiprenotazione,
                  backColor: color_accordion_richiestediprenotazione,
                  open: open_accordion_richiestediprenotazione,
                }}
              />
            </div>
          )}
        {stato == "ATTESA" &&
          accordion_listadiattesa != "" &&
          accordion_listadiattesa != null &&
          testo_accordion_listadiattesa != "" &&
          testo_accordion_listadiattesa != null && (
            <div>
              <FlightCardNote
                data={{
                  titolo: accordion_listadiattesa,
                  descrizione: testo_accordion_listadiattesa,
                  backColor: color_accordion_listadiattesa,
                  open: open_accordion_listadiattesa,
                }}
              />
            </div>
          )}
        {accordion_bambini != "" && accordion_bambini != null && testo_accordion_bambini != "" && testo_accordion_bambini != null && (
          <div>
            <FlightCardNote
              data={{
                titolo: accordion_bambini,
                descrizione: testo_accordion_bambini,
                backColor: color_accordion_bambini,
                open: open_accordion_bambini,
              }}
            />
          </div>
        )}
        <div className="flex justify-between text-neutral-6000 dark:text-neutral-300 py-2">
          <span>Domande?</span>{" "}
          <span style={{ color: "blue", textDecorationLine: "underline" }}>
            <Link to={{ pathname: "/faq" }} target="_blank">
              Vai alle FAQ
            </Link>
          </span>
        </div>
      </div>
    );
  };

  const renderRiassuntoPrezzi = () => {
    return (
      <>
        {paga_online == 0 && paga_struttura == 1 && (
          <Button
            onClick={() => {
              openModalPrezzi("struttura");
            }}
            sizeClass="px-8 py-0.5"
            fontSize="text-xs"
            className="bg-red-500 w-full border border-red-700 text-white hover:text-neutral-700 hover:bg-white font-medium"
          >
            Dettaglio prezzi!
          </Button>
        )}
        {
          paga_online == 1 && paga_struttura == 0 && (
            <Button
              onClick={() => {
                openModalPrezzi("online");
              }}
              sizeClass="px-8 py-0.5"
              fontSize="text-xs"
              className="bg-red-500 w-full border border-red-700 text-white hover:text-neutral-700 hover:bg-white font-medium"
            >
              Dettaglio prezzi!
            </Button>
          )
        }
        <div className="flex items-center" style={{ justifyContent: "space-between" }}>
          <div>
            <i className="las la-male text-lg mr-2"></i>
            <span className="text-sm text-neutral-500">Adulti</span>
          </div>
          <div className="flex flex-col ml-auto">
            <span className="text-sm text-neutral-500">
              {metodoPagamentoScelto == "struttura" ? "x" + adulti + " €" + tariffa_struttura : "x" + adulti + " €" + tariffa_online}
            </span>
          </div>
        </div>
        {bambini_ammessi == 1 ? (
          <>
            <div className="flex items-center" style={{ justifyContent: "space-between" }}>
              <div>
                <i className="las la-child text-lg mr-2"></i>
                <span className="text-sm text-neutral-500">Bambini Da 4 a 12 anni</span>
              </div>
              <div className="flex flex-col ml-auto">
                <span className="text-sm text-neutral-500">
                  {metodoPagamentoScelto == "struttura"
                    ? "x" + bambini + " €" + tariffa_struttura_bambino
                    : "x" + bambini + " €" + tariffa_online_bambino}
                </span>
              </div>
            </div>
            <div className="flex items-center" style={{ justifyContent: "space-between" }}>
              <div>
                <i className="las la-baby text-lg mr-2"></i>
                <span className="text-sm text-neutral-500">Bambini da 0 a 3 anni</span>
              </div>
              <div className="flex flex-col ml-auto">
                <span className="text-sm text-neutral-500">Gratis</span>
              </div>
            </div>
          </>
        ) : (
          <div className="flex items-center" style={{ justifyContent: "space-between" }}>
            <div>
              <i className="las la-child text-lg mr-2"></i>
              <span className="text-sm text-neutral-500">Bambini</span>
            </div>
            <div className="flex flex-col ml-auto">
              <span className="text-sm text-neutral-500">Non ammessi</span>
            </div>
          </div>
        )
        }
        {animali_ammessi == 1 ? (
          <div className="flex items-center" style={{ justifyContent: "space-between" }}>
            <div>
              <i className="las la-dog text-lg mr-2"></i>
              <span className="text-sm text-neutral-500">Animali</span>
            </div>
            <div className="flex flex-col ml-auto">
              <span className="text-sm text-neutral-500">{animali ? "Si" : "No"}</span>
            </div>
          </div>
        ) : (
          <div className="flex items-center" style={{ justifyContent: "space-between" }}>
            <div>
              <i className="las la-dog text-lg mr-2"></i>
              <span className="text-sm text-neutral-500">Animali</span>
            </div>
            <div className="flex flex-col ml-auto">
              <span className="text-sm text-neutral-500">Non ammessi</span>
            </div>
          </div>
        )
        }
        {options.length > 0 && (
          <div className="flex flex-col justify-between text-neutral-6000">
            {options.map((item: TipologiaServiziType) => {
              return (
                <div className="flex flex-row justify-between">
                  <span className="text-sm">
                    <i className="las la-wine-glass text-lg mr-2"></i>
                    {item.nome}
                  </span>{" "}
                  <span className="text-sm">
                    x{item.quantita} € {parseFloat(item.prezzo_adulto).toFixed(2)}
                  </span>
                </div>
              );
            })}
          </div>
        )
        }
        <div className="flex items-center" style={{ justifyContent: "space-between" }}>
          <div>
            <i className="la la-euro text-lg mr-2"></i>
            <span className="text-sm text-neutral-500">
              {metodoPagamentoScelto != "" ? "Metodo di pagamento" : "Devi ancora selezionare un metodo di pagamento"}
            </span>
          </div>
          <div className="flex flex-col ml-auto">
            <span className="text-sm text-red-500" style={{ textTransform: "capitalize" }}>
              {metodoPagamentoScelto}
            </span>
          </div>
        </div>
        {area != "" && (
          <div className="flex items-center" style={{ justifyContent: "space-between" }}>
            <div>
              <i className="las la-info text-lg mr-2"></i>
              <span className="text-sm text-neutral-500">Area assegnata</span>
            </div>
            <div className="flex flex-col ml-auto">
              <span className="text-sm text-neutral-500 font-bold">{area}</span>
            </div>
          </div>)
        }
        <div className="flex justify-between font-semibold py-2">
          <span>Totale</span>

          {prezzoServizioTipologiaAdulto == 0 && prezzoServizioTipologiaBambino == 0 && metodoPagamentoScelto == "online" && (
            <span>€ {((parseFloat(tariffa_online) * adulti) + (parseFloat(tariffa_online_bambino) * bambini) + quota_una_tantum + costoProdottoOpzione).toFixed(2)}</span>
          )}
          {prezzoServizioTipologiaAdulto == 0 && prezzoServizioTipologiaBambino == 0 && metodoPagamentoScelto == "struttura" && (
            <span>€ {((parseFloat(tariffa_struttura) * adulti) + (parseFloat(tariffa_struttura_bambino) * bambini) + quota_una_tantum + costoProdottoOpzione).toFixed(2)}</span>
          )}
          {(prezzoServizioTipologiaAdulto > 0 || (prezzoServizioTipologiaBambino > 0 && actionOpzioniEvento == 1)) && metodoPagamentoScelto == "online" && (
            <span>
              €{" "}
              {(
                parseFloat(tariffa_online) * adulti +
                parseFloat(tariffa_online_bambino) * bambini +
                quota_una_tantum +
                prezzoServizioTipologiaAdulto * adulti +
                prezzoServizioTipologiaBambino * bambini
              ).toFixed(2)}
            </span>
          )}
          {(prezzoServizioTipologiaAdulto > 0 || (prezzoServizioTipologiaBambino > 0 && actionOpzioniEvento == 1)) &&
            metodoPagamentoScelto == "struttura" && (
              <span>
                €{" "}
                {(
                  parseFloat(tariffa_online) * adulti +
                  parseFloat(tariffa_online_bambino) * bambini +
                  quota_una_tantum +
                  prezzoServizioTipologiaAdulto * adulti +
                  prezzoServizioTipologiaBambino * bambini
                ).toFixed(2)}
              </span>
            )}
        </div>
      </>)
  }

  const renderConfermaEPagaStruttura = () => {
    <div className="flex justify-between font-semibold">
      <span>Totale</span>
      <span>€ {tot_adulti_struttura + tot_bambini_struttura + quota_una_tantum + costoProdottoOpzione}</span>
    </div>;
    {
      importoAcconto > 0 && (
        <>
          <div className="flex justify-between dark:text-neutral-300">
            {text_acconto != "" && <span style={{ color: "red" }}>{text_acconto}</span>}
          </div>
          <div className="flex justify-between text-neutral-6000 dark:text-neutral-300">
            <span style={{ color: "red" }}>Acconto online</span>{" "}
            <span className="font-bold" style={{ color: "red" }}>
              € {importoAcconto}
            </span>
          </div>
          <div className="flex justify-between text-neutral-6000 dark:text-neutral-300">
            <span style={{ color: "blue" }}>Saldo in struttura</span>{" "}
            <span className="font-bold" style={{ color: "blue" }}>
              € {tot_adulti_struttura + tot_bambini_struttura + quota_una_tantum - importoAcconto}
            </span>
          </div>
        </>
      );
    }
  };

  const renderConfermaEPagaOnline = () => {
    return (
      <>
        <div className="flex justify-between font-semibold">
          <span>Totale</span>
          <span>€ {tot_adulti_online + tot_bambini_online + quota_una_tantum + costoProdottoOpzione} </span>
        </div>
        {importoAcconto > 0 && (
          <div>
            <div className="flex justify-between dark:text-neutral-300">
              {text_acconto != "" && <span style={{ color: "red" }}>{text_acconto}</span>}
            </div>
            <div className="flex justify-between text-neutral-6000 dark:text-neutral-300">
              <span style={{ color: "red" }}>Acconto online</span>{" "}
              <span className="font-bold" style={{ color: "red" }}>
                € {importoAcconto}
              </span>
            </div>
            <div className="flex justify-between text-neutral-6000 dark:text-neutral-300">
              <span style={{ color: "blue" }}>Saldo in struttura</span>{" "}
              <span className="font-bold" style={{ color: "blue" }}>
                € {tot_adulti_struttura + tot_bambini_struttura + quota_una_tantum - importoAcconto}
              </span>
            </div>
          </div>
        )}
      </>
    );
  };

  const sezioneButtonPagamento = () => {
    return (
      <div className="flex flex-col space-y-6">
        {options?.length > 0 && (
          <div className="flex flex-col justify-between text-neutral-6000">
            <span className="flex font-bold">Servizi aggiuntivi selezionati</span>
            {options?.map((item: any) => {
              return (
                <div className="flex flex-row justify-between">
                  <span className="ml-4 text-sm">{item.nome}</span> <span className="text-sm">€ {parseFloat(item.prezzo_adulto).toFixed(2)}</span>
                </div>
              );
            })}
          </div>
        )}
        {quota_una_tantum > 0 && (
          <div className="flex justify-between text-neutral-6000 dark:text-neutral-300">
            <span>Prenotazione</span> <span>€ {quota_una_tantum.toFixed(2)}</span>
          </div>
        )}
      </div>
    );
  };

  const renderInputMetodoDiPagamentoOnline = () => {
    return (
      <div
        className={`flex-1 border border-2 divide-x divide-neutral-200 rounded-md ${metodoPagamentoScelto == "online" ? "border-primary-100" : "border-gray-300"
          }`}
      >
        <div
          onClick={() => {
            setMetodoPagamento("online");
          }}
          className="flex flex-col p-2"
        >
          <div className="flex flex-row" style={{ justifyContent: "space-between" }}>
            <a
              onClick={() => {
                setMetodoPagamento("online");
              }}
              className={"ml-auto"}
            >
              <SaleOffBadge desc="Pagamento online" className="-mt-5"></SaleOffBadge>
            </a>
          </div>
          <div className="flex flex-col mt-2">
            <div className="flex space-x-2">
              <i className="las la-male text-lg"></i>
              <div className="flex flex-col">
                <span className="text-sm text-neutral-500 dark:text-neutral-400">€{tariffa_online}</span>
                <span className="text-xs text-neutral-500 dark:text-neutral-400" style={{ fontStyle: "italic" }}>
                  Per adulto
                </span>
              </div>
            </div>
            <div className="flex space-x-2">
              <i className="las la-child text-lg"></i>
              <div className="flex flex-col">
                <span className="text-sm text-neutral-500 dark:text-neutral-400">€{tariffa_online_bambino}</span>
                <span className="text-xs text-neutral-500 dark:text-neutral-400" style={{ fontStyle: "italic" }}>
                  Per bambino
                </span>
              </div>
            </div>
          </div>
          <div className="flex flex-col mt-2">
            <div className="flex flex-row" style={{ alignItems: "center" }}>
              <div className="flex-1 space-x-2">
                <i className="las la-check text-lg"></i>
                <span className="text-sm">Salta la fila</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };

  const renderInputMetodoDiPagamentoStruttura = () => {
    return (
      <div
        className={`flex-1 border border-2 ml-1 divide-x divide-neutral-200 rounded-md ${metodoPagamentoScelto == "struttura" ? "border-primary-100" : "border-gray-300"
          }`}
      >
        <div
          onClick={() => {
            setMetodoPagamento("struttura");
          }}
          className="flex flex-col p-2"
        >
          <div className="flex flex-row" style={{ justifyContent: "space-between" }}>
            <a
              onClick={() => {
                setMetodoPagamento("struttura");
              }}
              className={"ml-auto"}
            >
              <SaleOffBadgeGrey desc="Pagamento in struttura" className="-mt-5"></SaleOffBadgeGrey>
            </a>
          </div>
          <div className="flex flex-col mt-2">
            <div className="flex space-x-2">
              <i className="las la-male text-lg"></i>
              <div className="flex flex-col">
                <span className="text-sm text-neutral-500 dark:text-neutral-400">€{tariffa_struttura}</span>
                <span className="text-xs text-neutral-500 dark:text-neutral-400" style={{ fontStyle: "italic" }}>
                  Per adulto
                </span>
              </div>
            </div>
            <div className="flex space-x-2">
              <i className="las la-child text-lg"></i>
              <div className="flex flex-col">
                <span className="text-sm text-neutral-500 dark:text-neutral-400">€{tariffa_struttura_bambino}</span>
                <span className="text-xs text-neutral-500 dark:text-neutral-400" style={{ fontStyle: "italic" }}>
                  Per bambino
                </span>
              </div>
            </div>
          </div>
          <div className="flex flex-col mt-2">
            <div className="flex flex-row">
              <div className="flex-1 space-x-2">
                <i className="las la-times text-lg"></i>
                <span className="text-sm">Salta la fila</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };

  const renderModalResumeAcquisto = () => {
    return (
      <Transition appear show={isOpenModalPrezzi} as={Fragment}>
        <Dialog as="div" className="fixed inset-0 z-50 overflow-y-auto" onClose={closeModalPrezzi}>
          <div className="min-h-screen px-4 text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="fixed inset-0 bg-black bg-opacity-40" />
            </Transition.Child>

            {/* This element is to trick the browser into centering the modal contents. */}
            <span className="inline-block h-screen align-middle" aria-hidden="true">
              &#8203;
            </span>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <div className="inline-block py-8 w-full">
                <div className="inline-flex flex-col w-full max-w-4xl text-left align-middle transition-all transform overflow-hidden rounded-2xl bg-white dark:bg-neutral-900 dark:border dark:border-neutral-700 dark:text-neutral-100 shadow-xl h-full">
                  <div className="relative flex-shrink-0 px-6 py-4 border-b border-neutral-200 dark:border-neutral-800 text-center mb-5">
                    <h3 className="text-lg font-medium leading-6 text-gray-900" id="headlessui-dialog-title-70">
                      Riassunto prezzi
                    </h3>
                    <span className="absolute left-3 top-3">
                      <ButtonClose onClick={closeModalPrezzi} />
                    </span>
                  </div>
                  {prezzoShowModal == "online" ? renderRiassuntoPrezziOnline() : renderRiassuntoPrezziStruttura()}

                </div>
              </div>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition>
    );
  };

  const renderRiassuntoPrezziOnline = () => {
    return (
      <div className="px-5 overflow-auto text-neutral-700 dark:text-neutral-300 divide-y divide-neutral-200">
        {adulti > 0 && (
          <div className="mb-10">
            <div className="flex justify-between text-neutral-6000 dark:text-neutral-300">
              <span className="font-bold">{evento_token == 1 ? "Tariffa adulti" : "Adulti"}</span>{" "}
              <span>
                € {(parseFloat(tariffa_online) + prezzoServizioTipologiaAdulto).toFixed(2)} x{adulti}
              </span>
            </div>
            {evento_token == 1 && options.length == 0 && (
              <>
                <span className="text-sm underline text-neutral-6000">di cui:</span>
                <div className="flex justify-between text-neutral-6000 dark:text-neutral-300 text-sm">
                  <span className="ml-4">Credito Food & Beverage</span>{" "}
                  <span>
                    € {(parseFloat(tariffa_online) - totale_adulti_servizi + prezzoServizioTipologiaAdulto).toFixed(2)} x{adulti}
                  </span>
                </div>
              </>
            )}
            {serviziSelected.map((item) => {
              if (item.price_adulti != "") {
                return (
                  <div className="flex justify-between text-neutral-6000 dark:text-neutral-300 text-sm">
                    <span className="ml-4" style={{ color: "green", textTransform: "capitalize" }}>
                      {item.nome}
                    </span>{" "}
                    <span style={{ color: "green" }}>
                      € {item.price_adulti} x{adulti}
                    </span>
                  </div>
                );
              }
            })}
            {options.map((item: TipologiaServiziType) => {
              if (evento_token == 1) {
                return (
                  <div className="flex justify-between text-neutral-6000 dark:text-neutral-300 text-sm">
                    <span className="ml-4" style={{ color: "green" }}>
                      {"Credito Food & Beverage"}
                    </span>{" "}
                    <span style={{ color: "green" }}>
                      € {(parseFloat(tariffa_online) - totale_adulti_servizi - prezzoServizioTipologiaAdultoToken).toFixed(2)} x{adulti}
                    </span>
                  </div>
                );
              }
            })}
          </div>
        )}

        {bambini > 0 && (
          <div className="mb-10">
            <hr />
            <div className="flex justify-between text-neutral-6000 dark:text-neutral-300">
              <span className="font-bold">{evento_token == 1 ? "Tariffa bambini" : "Bambini"}</span>{" "}
              <span>
                € {(parseFloat(tariffa_online_bambino) + prezzoServizioTipologiaBambino).toFixed(2)} x{bambini}
              </span>
            </div>
            {evento_token == 1 && options.length == 0 && (
              <>
                <span className="text-sm underline text-muted">di cui:</span>
                <div className="flex justify-between text-neutral-6000 dark:text-neutral-300 text-sm">
                  <span className="ml-4">Credito Food & Beverage</span>{" "}
                  <span>
                    € {(parseFloat(tariffa_online_bambino) - totale_bambini_servizi + prezzoServizioTipologiaBambino).toFixed(2)} x{bambini}
                  </span>
                </div>
              </>
            )}
            {serviziSelected.map((item) => {
              if (item.price_bambino != "") {
                return (
                  <div className="flex justify-between text-neutral-6000 dark:text-neutral-300 text-sm">
                    <span className="ml-4" style={{ color: "green", textTransform: "capitalize" }}>
                      {item.nome == 'LETTINO' ? 'INGRESSO' : item.nome}
                    </span>{" "}
                    <span style={{ color: "green" }}>
                      € {parseFloat(item.price_bambino).toFixed(2)} x{bambini}
                    </span>
                  </div>
                );
              }
            })}
            {options.map((item: TipologiaServiziType) => {
              if (evento_token == 1) {
                return (
                  <div className="flex justify-between text-neutral-6000 dark:text-neutral-300 text-sm">
                    <span className="ml-4" style={{ color: "green" }}>{`Credito Food & Beverage`}</span>{" "}
                    <span style={{ color: "green" }}>
                      € {(parseFloat(tariffa_online_bambino) - totale_bambini_servizi - prezzoServizioTipologiaBambinoToken).toFixed(2)} x{bambini}
                    </span>
                  </div>
                );
              }
            })}
          </div>
        )}

        {bambiniBaby > 0 && (
          <div className="mb-10">
            <hr />
            <div className="flex justify-between text-neutral-6000 dark:text-neutral-300">
              <span className="font-bold">Bambini inferiori a 3 anni</span> <span>Gratis x{bambiniBaby}</span>
            </div>
          </div>
        )}
        {renderRiassuntoAltroCarrello()}
      </div>
    );
  };

  const renderRiassuntoPrezziStruttura = () => {
    return (
      <div className="px-5 overflow-auto text-neutral-700 dark:text-neutral-300 divide-y divide-neutral-200">
        {adulti > 0 && (
          <div className="mb-10">
            <div className="flex justify-between text-neutral-6000 dark:text-neutral-300">
              <span className="font-bold">{evento_token == 1 ? "Tariffa adulti" : "Adulti"}</span>{" "}
              <span>
                € {(parseFloat(tariffa_struttura) + prezzoServizioTipologiaAdulto).toFixed(2)} x{adulti}
              </span>
            </div>
            {evento_token == 1 && options.length == 0 && (
              <>
                <span className="text-sm underline text-neutral-6000">di cui:</span>
                <div className="flex justify-between text-neutral-6000 dark:text-neutral-300 text-sm">
                  <span className="ml-4">Credito Food & Beverage</span>{" "}
                  <span>
                    € {(parseFloat(tariffa_struttura) - totale_adulti_servizi + prezzoServizioTipologiaAdulto).toFixed(2)} x{adulti}
                  </span>
                </div>
              </>
            )}
            {serviziSelected.map((item) => {
              if (item.price_adulti != "") {
                return (
                  <div className="flex justify-between text-neutral-6000 dark:text-neutral-300 text-sm">
                    <span className="ml-4" style={{ color: "green", textTransform: "capitalize" }}>
                      {item.nome}
                    </span>{" "}
                    <span style={{ color: "green" }}>
                      € {item.price_adulti} x{adulti}
                    </span>
                  </div>
                );
              }
            })}
            {options.map((item: TipologiaServiziType) => {
              if (evento_token == 1) {
                return (
                  <div className="flex justify-between text-neutral-6000 dark:text-neutral-300 text-sm">
                    <span className="ml-4" style={{ color: "green" }}>
                      {"Credito Food & Beverage"}
                    </span>{" "}
                    <span style={{ color: "green" }}>
                      € {(parseFloat(tariffa_struttura) - totale_adulti_servizi - prezzoServizioTipologiaAdultoToken).toFixed(2)} x{adulti}
                    </span>
                  </div>
                );
              }
            })}
          </div>
        )}

        {bambini > 0 && (
          <div className="mb-10">
            <hr />
            <div className="flex justify-between text-neutral-6000 dark:text-neutral-300">
              <span className="font-bold">{evento_token == 1 ? "Tariffa bambini" : "Bambini"}</span>{" "}
              <span>
                € {(parseFloat(tariffa_struttura_bambino) + prezzoServizioTipologiaBambino).toFixed(2)} x{bambini}
              </span>
            </div>
            {evento_token == 1 && options.length == 0 && (
              <>
                <span className="text-sm underline text-muted">di cui:</span>
                <div className="flex justify-between text-neutral-6000 dark:text-neutral-300 text-sm">
                  <span className="ml-4">Credito Food & Beverage</span>{" "}
                  <span>
                    € {(parseFloat(tariffa_struttura_bambino) - totale_bambini_servizi + prezzoServizioTipologiaBambino).toFixed(2)} x{bambini}
                  </span>
                </div>
              </>
            )}
            {serviziSelected.map((item) => {
              if (item.price_bambino != "") {
                return (
                  <div className="flex justify-between text-neutral-6000 dark:text-neutral-300 text-sm">
                    <span className="ml-4" style={{ color: "green", textTransform: "capitalize" }}>
                      {item.nome == 'LETTINO' ? 'INGRESSO' : item.nome}
                    </span>{" "}
                    <span style={{ color: "green" }}>
                      € {parseFloat(item.price_bambino).toFixed(2)} x{bambini}
                    </span>
                  </div>
                );
              }
            })}
            {options.map((item: TipologiaServiziType) => {
              if (evento_token == 1) {
                return (
                  <div className="flex justify-between text-neutral-6000 dark:text-neutral-300 text-sm">
                    <span className="ml-4" style={{ color: "green" }}>{`Credito Food & Beverage`}</span>{" "}
                    <span style={{ color: "green" }}>
                      € {(parseFloat(tariffa_struttura_bambino) - totale_bambini_servizi - prezzoServizioTipologiaBambinoToken).toFixed(2)} x{bambini}
                    </span>
                  </div>
                );
              }
            })}
          </div>
        )}

        {bambiniBaby > 0 && (
          <div className="mb-10">
            <hr />
            <div className="flex justify-between text-neutral-6000 dark:text-neutral-300">
              <span className="font-bold">Bambini inferiori a 3 anni</span> <span>Gratis x{bambiniBaby}</span>
            </div>
          </div>
        )}

        {renderRiassuntoAltroCarrello()}
      </div>
    );
  };

  const renderRiassuntoAltroCarrello = () => {
    return (
      <div className="space-y-2">
        {options.length > 0 && (
          <>
            <div className="flex flex-col justify-between text-neutral-6000 pb-4">
              <span className="flex font-bold">Servizi aggiuntivi selezionati</span>
              {options.map((item: TipologiaServiziType) => {
                return (
                  <div className="flex flex-row justify-between">
                    <span className="ml-4 text-sm">{item.nome}</span>{" "}
                    <span className="text-sm">
                      x{item.quantita} € {parseFloat(item.prezzo_adulto).toFixed(2)}
                    </span>
                  </div>
                );
              })}
            </div>
            <hr />
          </>
        )}

        {quota_una_tantum > 0 && (
          <>
            <div className="flex justify-between text-neutral-6000 pb-4">
              <span className="flex font-bold">Prenotazione</span> <span>€ {quota_una_tantum.toFixed(2)}</span>
            </div>
            <hr />
          </>
        )}
      </div>
    );
  };

  return (
    <div className={`nc-CheckOutPage ${className}`} data-nc-id="CheckOutPage">
      <main className="container mt-11 mb-24 lg:mb-32 flex flex-col-reverse lg:flex-row">
        <div className="w-full lg:w-3/5 xl:w-2/3 lg:pr-10">{richiestaCliente()}</div>
        {viewCart == true && <div className="w-full lg:w-3/5 xl:w-2/3 lg:pr-10">{renderSidebar()}</div>}
        {renderModalResumeAcquisto()}
      </main>
    </div>
  );
};

export default CheckOutPage;
